import React, {useEffect, useCallback, useState, useRef} from 'react';
import ColorPicker from 'material-ui-color-picker'
 
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditColor() {
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState('#000');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();
     
      const name = e.target.name.value;
      const description = e.target.description.value;

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),              
      });         

      await schema.validate({name}, {
        abortEarly: false,
      });

      const postData = {  
        name,  
        description,
        color
      };

      if (action === 'update'){
        await api.put(`/color/${id}`, postData);
      }
      else{
        await api.post(`/color`, postData);
      }


      history.push('/admin/colors');
   
    }
    catch(e){ 

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
 
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
           
  
    }
  },[action, color]); 
  

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/color/${id}`);
      setName(response.data.name);    
      setDescription(response.data.description);
      setColor(response.data.color);

    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/colors");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados da Cor</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                  />

                  <ColorPicker
                    name='color'
                    variant="outlined"
                    fullWidth
                    showPicker={true}
                    defaultValue='#000'
                    value={color}
                    onChange={color => setColor(color)}
                  
                  />                  
                  <div style={{marginTop: 10, backgroundColor: color, width: 50, height: 50}}></div>


                  <TextField
                      variant="outlined"
                      margin="normal"                     
                      multiline={true}
                      rows={10}
                      fullWidth
                      id="description"
                      label="Descrição"
                      name="description"                     
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
           
                   />  
                </GridItem>               
              </GridContainer>
             
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
