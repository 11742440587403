import React, {useEffect, useCallback, useState, useRef} from 'react';

import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import FileUploader from 'components/FileUploader/FileUploader';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditCard() {

  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [imagem, setImagem] = useState('');
  const [removerImagem, setRemoverImagem] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();
      setLoading(true);


      if (!selectedFile && action!='update'){
        setLoading(false);
        addToast('É necessário ao menos informar a imagem', {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }
     
      const name = e.target.name.value;

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),              
      });         

      await schema.validate({name}, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('name', name);
      
      if (selectedFile){
        formData.append('photo', selectedFile);
      }
 
      if (action === 'update'){
        await api.put(`/card/${id}`, formData);
      }
      else{
        await api.post(`/card`, formData);
      }

      setLoading(false);
      history.push('/admin/card');

   
    }
    catch(e){ 

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }     
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });           
  
    }
  },[action, selectedFile]); 
  
  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/card/${id}`);
      setName(response.data.name);    
      setImagem(response.data.photo);
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/card");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do Cartão</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
             {!loading ? <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                  />

                  <FileUploader
                    onFileSelectSuccess={(file) => { 
                       setSelectedFile(file);                     
                       setImagem(null); 
                       setRemoverImagem(false);
                    }}
                    onFileSelectError={({ error }) => addToast(error, {
                      appearance: 'error',
                      autoDismiss: true,
                    })}
                 />

                  {imagem ?
                  <>     
                    <hr />
                       <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/card/rs/${imagem}`} height="250" />       
                    <hr />
                  </> : null}


                </GridItem>               
              </GridContainer> : <Box mt={6}><CircularProgress /></Box> }
             
            </CardBody>
            {!loading ? <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter> : null}
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
