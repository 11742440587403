import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TextField from '@material-ui/core/TextField';

import Button from 'components/CustomButtons/Button.js';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import AddAPhoto from '@material-ui/icons/AddAPhoto';
import GetApp from '@material-ui/icons/GetApp';


// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { tableHead, tableColumn, tableData, resource, tableHeaderColor, tableColumnSize, onEdit, onDelete, onAddPhoto, onDownloadXml } = props;
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    style={{ width: tableColumnSize[key] }}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((data, key) => {

            return (
              <TableRow key={key} className={classes.tableBodyRow}>
                {tableColumn.map((prop, key) => {
                  return (
                    <TableCell className={classes.tableCell} key={key}>
                    
                      {data[prop] && prop !== 'image' ? data[prop] : null}
                      {prop === 'image' && data[prop] ? <a href={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/label/${data["image"]}`} target="_blank"><img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/label/${data["image"]}`} width={150} /></a> : null}
                      
                      {prop === 'box_color' ? <div style={{ marginTop: 10, backgroundColor: data["color"], width: 30, height: 30 }}></div> : null}
                      {prop === 'label' && data["photo"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/label/th/${data["photo"]}`}></img> : null}
                      {prop === 'card' && data["photo"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/card/th/${data["photo"]}`}></img> : null}
                      {prop === 'stone' && data["photo"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/stone/th/${data["photo"]}`}></img> : null}
                      {prop === 'scent' && data["photo"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/scent/th/${data["photo"]}`}></img> : null}
                      {prop === 'preset' && data["photo"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/preset/th/${data["photo"]}`}></img> : null}
                      {prop === 'topImage' && data["vistacima"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/productComposition/th/${data["vistacima"]}`}></img> : null}
                      {prop === 'frontalImage' && data["destaque"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/productComposition/th/${data["destaque"]}`}></img> : null}
                     
                      {prop === 'decoration' && data["photo"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/decoration/th/${data["photo"]}`}></img> : null}
                      {prop === 'jar' && data["photo"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/jar/th/${data["photo"]}`}></img> : null}
                      {prop === 'banner' && data["photo"] ? <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/banners/th/${data["name"]}`}></img> : null}
                      {prop === 'edit' ? <Link to={`/admin/${resource}/${data["id"]}`}><Button color="info" round><Edit /></Button></Link> : null}
                      {prop === 'delete' && data["id"] !== 'a8c55ccd-fc57-4b9f-a2f7-9cb05ca6f865' ? <Button color="danger" round onClick={() => onDelete(data["id"])}><Delete /></Button> : null}
                      {prop === 'foto' ? <Button color="primary" round onClick={() => onAddPhoto(data["id"], data["nome"])}><AddAPhoto /></Button> : null}
                      {prop === 'xml' && (data["statusid"] === "5ca99ecf-248a-4d8c-b007-9a4920fdc0c8" || data["statusid"] === "d8495bf0-5e46-4085-b02c-b805c821ca35" || data["statusid"] === "fe6a9772-ff31-4202-a41c-f066734cc575") ? <Button color="warning" round onClick={() => onDownloadXml(data["id"], data["nome"])}><GetApp /></Button> : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
};
