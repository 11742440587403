import React, {useEffect, useCallback, useState, useRef} from 'react';

import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

// @material-ui/core components

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import FileUploader from 'components/FileUploader/FileUploader';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditAcessory() {

  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();
      setLoading(true);
     

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),   
        description: Yup.string().required('Descrição é obrigatório !'),            
        price: Yup.string().required('Valor é obrigatório !'),         
      });         

      await schema.validate({name, description, price}, {
        abortEarly: false,
      });

    
      if (action === 'update'){
        await api.put(`/product/${id}`,{
          product_type_id: '6808fa18-149d-488e-9c3f-9c4955a586eb',
          name,
          description,
          price
        });
      }
      else{
        await api.post(`/product`, {
          product_type_id: '6808fa18-149d-488e-9c3f-9c4955a586eb',
          name,
          description,
          price
        });
      }

      setLoading(false);
      history.push('/admin/acessories');

   
    }
    catch(e){ 

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }     
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });           
  
    }
  },[action, name, description, price]); 
  
  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/product/${id}`);
      setName(response.data.name); 
      setDescription(response.data.description);
      setPrice(response.data.price);     
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/acessories");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do Acessório</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
             {!loading ? <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                  />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="description"
                      label="Descrição"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    
                  />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>

                <CurrencyTextField
                 
                  label="Valor"
                  variant="standard"
                  value={price}
                  currencySymbol="R$"
                  variant="outlined"
                  margin="normal"       
                  fullWidth
                  textAlign="left"              
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator=""
                  name="valor"
                  onChange={(event, value)=> setPrice(value)}
               />
                </GridItem>
               
              </GridContainer> : <Box mt={6}><CircularProgress /></Box> }
             
            </CardBody>
            {!loading ? <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter> : null}
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
