import React, { useEffect, useCallback, useState, useRef } from 'react';
import MelhorEnvio from 'menv-js';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Table from 'components/Table/Table.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import apiFrete from '../../services/apiFrete';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function EditOrder() {
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingEtiqueta, setLoadingEtiqueta] = useState(false);
  const [url, setUrl] = useState();
  const [nf, setNF] = useState('');
  const [data, setData] = useState();
  const [dataStatus, setDataStatus] = useState();
  const [total, setTotal] = useState('R$ 0,00');
  const [itens, setItens] = useState([]);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();
  const classes = useStyles();
  let query = useQuery();

  useEffect(() => {
    getPaymentStatusOptions();
    loadData(id);
  }, []);


  const getPaymentStatusOptions = useCallback(async () => {
    try {
      const response = await api.get(`/orders/paymentstatus`);
      setDataStatus(response.data.status_options);
    }
    catch (e) { }
  }, []);

 
  

  const gerarEtiqueta = useCallback(async (volumes) => {
    try {
     
      const vols = volumes.replace(/\\/g, '');
      const info = JSON.parse(vols);

      const productsData = [];
      const volumesData = [];

      data.itens.map(p => {
        productsData.push(
          {
            "name": p.product.nome,
            "quantity": 1,
            "unitary_value": p.value
          },
        )
      });

      info.map(v => {
        volumesData.push({
          width: v.dimensions.width,
          height: v.dimensions.height,
          length: v.dimensions.length,
          weight: v.weight,
        });
      })

      /*
      const agency = await apiFrete.get(`/api/v2/me/shipment/agencies?company=2&country=BR&state=RJ&city=RIO DE JANEIRO`);

      let cart = null;

      if (temAgency) {
        cart = await menv.addItemInCart({
          "service": service.id,
          "agency": temAgency ? agency.data[0].id : 0,
          "from": {
            "name": "Crie suas velas",
            "phone": "21998831109",
            "email": "contato@criesuasvelas.com.br",
            "company_document": "48591825000154",
            "state_register": "",
            "address": "AV DAS AMERICAS",
            "complement": "BLC 1 SALA 305",
            "number": "04200",
            "district": "BARRA DA TIJUCA",
            "city": "RIO DE JANEIRO",
            "country_id": "BR",
            "postal_code": "22640907",
            "note": ""
          },
          "to": {
            "name": data.customer.name,
            "phone": data.customer.tel_01.replace(/\D/g, ""),
            "email": data.customer.email,
            "document": data.customer.type === 'PF' ? data.customer.document.replace(/\D/g, "") : '',
            "company_document": data.customer.type === 'PJ' ? data.customer.document.replace(/\D/g, "") : '',
            "state_register": data.customer.type === 'PJ' ? data.customer.inscricao.replace(/\D/g, "") : '',
            "address": data.address.endereco,
            "complement": data.address.complemento,
            "number": data.address.numero,
            "district": data.address.bairro,
            "city": data.address.cidade,
            "state_abbr": data.address.estado,
            "country_id": "BR",
            "postal_code": data.address.cep,
            "note": ``
          },
          "products": productsData,
          "volumes": volumesData,
          "options": {
            "insurance_value": data.total,
            "receipt": false,
            "own_hand": false,
            "reverse": false,
            "non_commercial": false,
            "invoice": {
              "key": nf
            },
            "platform": "Crie suas velas",
            "tags": [
              {
                "tag": `Pedido Site # ${data.id}`,
                "url": null
              }
            ]
          }
        });
      }
      else {
        cart = await menv.addItemInCart({
          "service": service.id,
          "from": {
            "name": "Crie suas velas",
            "phone": "21998831109",
            "email": "contato@criesuasvelas.com.br",
            "company_document": "48591825000154",
            "state_register": "",
            "address": "AV DAS AMERICAS",
            "complement": "BLC 1 SALA 305",
            "number": "04200",
            "district": "BARRA DA TIJUCA",
            "city": "RIO DE JANEIRO",
            "country_id": "BR",
            "postal_code": "22640907",
            "note": ""
          },
          "to": {
            "name": data.customer.name,
            "phone": data.customer.tel_01.replace(/\D/g, ""),
            "email": data.customer.email,
            "document": data.customer.type === 'PF' ? data.customer.document.replace(/\D/g, "") : '',
            "company_document": data.customer.type === 'PJ' ? data.customer.document.replace(/\D/g, "") : '',
            "state_register": data.customer.type === 'PJ' ? data.customer.inscricao.replace(/\D/g, "") : '',
            "address": data.address.endereco,
            "complement": data.address.complemento,
            "number": data.address.numero,
            "district": data.address.bairro,
            "city": data.address.cidade,
            "state_abbr": data.address.estado,
            "country_id": "BR",
            "postal_code": data.address.cep,
            "note": ``
          },
          "products": productsData,
          "volumes": volumesData,
          "options": {
            "insurance_value": data.total,
            "receipt": false,
            "own_hand": false,
            "reverse": false,
            "non_commercial": false,
            "invoice": {
              "key": nf
            },
            "platform": "Crie sua vela",
            "tags": [
              {
                "tag": `Pedido Site # ${data.id}`,
                "url": null
              }
            ]
          }
        });

      }

      const order = await menv.checkout({
        orders: [
          cart.id
        ]
      });

      const label = await menv.generateLabel({
        orders: [
          cart.id
        ]
      });

      const print = await menv.printLabel({
        orders: [
          cart.id
        ]
      });
      */

      const response = await api.post(`/orders/generatelabel/${data.id}`, {
        productsData,
        volumesData,
        customer: data.customer,
        address: data.address,
        order_id: data.id,
        nf,
        total: data.total,
        frete: data.freteOpcao
      });

      setLoadingEtiqueta(false);
      setUrl(response.data.url);
   
      
    }
    catch (e) {
      console.log(e.message);
      setLoadingEtiqueta(false);
      addToast('Ocorreu um erro ! Verifique se tem saldo e se a chave NFE foi informada', {
        appearance: 'error',
        autoDismiss: true,
      });
    }

  }, [data, nf]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const itens = [];
      const response = await api.get(`/orders/${id}`);

      setData(response.data);
      setStatus(response.data.status_id);


      let totalv = 0;

      response.data.itens.map(i => {


        let product = i.jar?.name || i.otherProduct?.name;

        if (i.jar) {
          product += ' - Nota de saida: ' + i.scent_topo?.name;
          product += ' - Nota de corpo: ' + i.scent_meio?.name;
          product += ' - Nota de base: ' + i.scent_base?.name;
          i.color ? product += ' - Cor: ' + i.color?.name : '';
          i.stone ? product += ' - Pedra: ' + i.stone?.name : '';
          i.label_format ? product += ' - Forma do Rotulo ' + i.label_format : '';
          i.label ? product += ' - Tema do Rotulo: ' + i.label?.name : '';
          i.decoration ? product += ' - Decoração: ' + i.decoration?.name : '';
          i.card ? product += ' - Cartão: ' + i.card?.name : '';
          i.message ? product += ' - Mensagem: ' + i.message : '';
        }

        itens.push({
          product,
          qtde: i.qtde,
          image: i.image,
          value: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(i.value),
          subtotal: new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format((i.qtde * i.value)),
          discount: i.discount > 0 ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format((i.discount)) : 'R$ 0,00',
          coupon: i.coupon ? i.coupon.code : '-x-'
        })


        totalv += Number(i.qtde) * Number(i.value);
      })

      const fretev = Number(response.data.freteValor);
      const descontov = Number(response.data.valordesconto);
      setTotal(new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(totalv + fretev - descontov));

      setItens(itens);
      setUrl(response.data.url);
      setNF(response.data.nf);

      setLoading(false);

    }
    catch (e) {

      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

      history.push("/admin/orders");
    }
  });

  const updateStatus = useCallback(async () => {
    try {

      await api.patch(`/orders/${id}`, {
        status_id: status
      });

      addToast('Atualizado com sucesso', {
        appearance: 'success',
        autoDismiss: true,
      });

    }
    catch (e) {

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

    }
  }, [status]);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do pedido</h4>
            </CardHeader>
            <CardBody>

              {loading ? (
                <Box mt={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="nopedido"
                        label="No Pedido"
                        name="nopedido"
                        disabled
                        value={data.id}
                        autoFocus
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label={data.customer.type === 'PF' ? 'Nome' : 'Razão Social'}
                        name="name"
                        disabled
                        value={data.customer.name}
                        autoFocus
                      />
                    </GridItem>
                  </GridContainer>

                  {data.customer.type === "PJ" ? <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Inscrição Estadual"
                        name="inscricao"
                        disabled
                        value={data.customer.inscricao}
                        autoFocus
                      />
                    </GridItem>
                  </GridContainer> : null}


                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Email"
                        name="email"
                        disabled
                        value={data.customer.email}
                        autoFocus
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="cpfcnpj"
                        label="CPF/CNPJ"
                        name="document"
                        disabled
                        value={data.customer.document}
                        autoFocus
                      />
                    </GridItem>
                  </GridContainer>


                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="telefone"
                        label="Telefone 1"
                        name="telefone"
                        disabled
                        value={data.customer.tel_01}
                        autoFocus
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="telefone"
                        label="Telefone 2"
                        name="telefone"
                        disabled
                        value={data.customer.tel_02}
                        autoFocus
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="telefone"
                        label="Telefone 3"
                        name="telefone"
                        disabled
                        value={data.customer.tel_03}
                        autoFocus
                      />
                    </GridItem>
                  </GridContainer>



                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="formapg"
                        label="Forma de pagamento"
                        name="formapg"
                        disabled
                        value={data.paymentform.name}
                        autoFocus
                      />
                    </GridItem>
                  </GridContainer>


                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="total"
                        label="Total"
                        name="total"
                        disabled
                        value={total}
                        autoFocus
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="cadastrado"
                        label="Cadastrado em"
                        name="cadastrado"
                        disabled
                        value={new Date(data.created_at).toLocaleDateString('pt-br', {
                          day: '2-digit',
                          month: 'long',
                          year: 'numeric'
                        })}
                        autoFocus
                      />
                    </GridItem>
                  </GridContainer>

                  <h3>Endereço para entrega</h3>

                  <strong>{data.address.identificacao}</strong><br />
                  {data.address.endereco} {data.address.numero} {data.address.complemento} {data.address.bairro}<br />{data.address.cidade} {data.address.estado} CEP: {data.address.cep}

                  <h3>Status do pedido</h3>

                  <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 25 }}>
                    <InputLabel id="ordem_label">Status atual do pedido:</InputLabel>
                    <Select
                      labelId="status"
                      label="Status Atual do Pedido"
                      variant="outlined"
                      name="status"
                      fullWidth
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}>
                      {dataStatus && dataStatus.map(e => (
                        <MenuItem value={e.id}>
                          <em>{e.name}</em>
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                  <Button color="warning" type="button" onClick={() => updateStatus()}>Atualizar Status</Button>

                  <h3>Informações adicionais</h3>

                  {data.boleto_url ? <p><strong>Boleto: </strong> <a href={data.boleto_url} target="_blank">{data.boleto_url}</a></p> : null}
                  {data.brand ? <p><strong>Cartão: </strong> {data.brand} - {data.last_digits}</p> : null}
                  <p><strong>Id transação: </strong> {data.transaction_id}</p>
                  {data.parcelas ? <p><strong>No Parcelas: </strong> {data.parcelas}</p> : null}
                  {data.nsu ? <p><strong>NSU: </strong> {data.nsu}</p> : null}
                  {data.authcode ? <p><strong>COD AUTORIZAÇÃO: </strong> {data.authcode}</p> : null}
                  {data.finished_at ? <p><strong>Pedido finalizado em: </strong> {new Date(data.finished_at).toLocaleDateString('pt-br', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                  })}</p> : null}


                  <h3>Itens do pedido</h3>

                  {itens && itens.length > 0 ? <Table
                    tableHeaderColor="primary"
                    tableHead={['Produto', 'Imagem', 'Qtde', 'Valor', 'Subtotal', 'Desconto', 'Cupom']}
                    tableColumn={['product', 'image', 'qtde', 'value', 'subtotal', 'discount', 'coupon']}
                    tableColumnSize={['25%', '10%', '10%', '15%', '15%', '15%', '15%']}
                    tableData={itens}
                    resource="customers"
                    onDelete={(id) => handleDelete(id)} /> : <p style={{ marginTop: 20 }}>Nenhum item cadastrado ao pedido.</p>}
                  <hr />
                  <h4>Frete: </h4><br />
                  {data.freteValor > 0 ? <><p><strong>Opção:</strong> {data.freteOpcao}</p>
                    <p><strong>Empresa:</strong> {data.freteEmpresa}</p>
                    <p><strong>Previsão:</strong> {data.fretePrevisao} dd</p>
                    <p><strong>Valor Frete:</strong></p>

                    <h4>(+) {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(data.freteValor)}</h4></> : <>{data.freteEmpresa === 'X' ? <p>Retirar na loja</p> : <p>Frete Grátis</p>}</>}

                  {data.tipodesconto ? <><hr /><h4>Descontos: </h4><br />
                    <p><strong>{data.tipodesconto}</strong></p>
                    <h4>(-) {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(data.valordesconto)}</h4>

                    <hr />

                    <h3>Valor recebido: {total}</h3>
                  </> : null}


                  <hr />
                  {data.freteValor > 0 ? <div>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="nf"
                      label="Chave NFE"
                      name="nf"
                      value={nf}
                      onChange={(e) => setNF(e.target.value)}

                    />

                    {!url ? <div>
                      {!loadingEtiqueta ? <Button color="info" type="button" onClick={() => gerarEtiqueta(data.volumes)}>Comprar / Gerar Etiqueta - Melhor Envio</Button> : <Box mt={6}><CircularProgress /></Box>}
                    </div> : null}

                  </div> : null}

                  {url && !loadingEtiqueta ? <a href={url} target="_blank"><Button color="danger" type="button">Imprimir Etiqueta</Button></a> : null}
                  <hr />
                </>

              )}

            </CardBody>
            <CardFooter>
              <Link to="/admin/orders"><Button color="primary" type="button">Voltar</Button></Link>
            </CardFooter>

          </Card>
        </GridItem>

      </GridContainer>
    </div>
  );
}
