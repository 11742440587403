import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

export default function Cards() {
  const classes = useStyles();  
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { addToast } = useToasts()

  useEffect(() => {
      getData();
  }, []);


  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get("/card");
      setData(response.data);
      setLoading(false);
    }
    catch(e){
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });     
      setLoading(false);   
    } 
  },[]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/card/${id}`);
          setData(data => data.filter(d => d.id != id));
          setLoading(false);
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });   
          
      }
  },[]);

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Cartões</h4>
            <p className={classes.cardCategoryWhite}>
              Lista de cartões cadastrados no sistema.
            </p>
          </CardHeader>
          <CardBody>

          <Link to="/admin/cards/new"> <Button color="info">Adicionar novo cartão</Button></Link>
 
          {data && data.length > 0 ? <Table
              tableHeaderColor="primary"
              tableHead={['Imagem', 'Nome', '', '']}
              tableColumn={['card', 'name',  'edit', 'delete']}
              tableColumnSize={['20%', '80%', '1%', '1%']}
              tableData={data} 
              resource="cards"            
              onDelete={(id) => handleDelete(id)}
           
           /> : isLoading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
          ) : (           
            <p style={{marginTop: 20}}>Nenhum cartão cadastrado no momento.</p>
          )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
