/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import {
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';

import { useAuth } from '../hooks/auth';

const Route = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();


  return ( 
    <ReactDOMRoute
      {...rest}
      render={({ location }) => (isPrivate === !!user ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: isPrivate ? '/' : '/admin/painel',
            state: { from: location },
          }}
        />
      ))}
    />
  );
};

export default Route;
