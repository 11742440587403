import React, {useEffect, useCallback, useState, useRef} from 'react';

import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Selection from 'components/Selection/Selection.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditProductComposition() {
  const [action, setAction] = useState('create');
  const [color, setColor] = useState('');
  const [productType, setProductType] = useState('');
  const [jar, setJar] = useState('');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);


  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();

      const postData = {  
        product_type_id: productType,
        color_id: color,
        jar_id: jar  
      };

      if (action === 'update'){
        await api.put(`/productComposition/${id}`, postData);
      }
      else{
        await api.post(`/productComposition`, postData);
      }

      history.push('/admin/compositions');
   
    }
    catch(e){   
       
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
             
    }
  },[action, color, jar, productType]); 
  

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/productComposition/${id}`);
      setJar(response.data.jar.id);    
      setColor(response.data.color.id);
      setProductType(response.data.productType.id);
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/compositions");
      
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Composição de Produto</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>


            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>                  
                <Selection resource="productType" label="Tipo de produto" handleChangeValue={(value) => setProductType(value)} marginTop={15} value={productType} />           
                </GridItem>               
              </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>                  
                <Selection resource="color" label="Cor" handleChangeValue={(value) => setColor(value)} marginTop={15} value={color} />           
                </GridItem>               
              </GridContainer>
           
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  
                <Selection resource="jar" label="Jarra" handleChangeValue={(value) => setJar(value)} marginTop={15} value={jar} />
           
                </GridItem>               
              </GridContainer>
             
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
