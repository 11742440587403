
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import 'rsuite/dist/styles/rsuite-default.css';

import { Link, useParams, useHistory } from 'react-router-dom';
import { Uploader } from 'rsuite';

import { setCookie, parseCookies } from 'nookies'

import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Delete from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import Selection from 'components/Selection/Selection.js';
import Button from 'components/CustomButtons/Button.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';



import api from '../../services/api';

const useStyles = makeStyles({
  root: {
    maxWidth: 350,    
  },
  destaque: {
    maxWidth: 350,   
    backgroundColor: '#FAFA'
  },
  media: {
    height: 350,
  },
  boxupload: {
    lineHeight: '150px',
    backgroundColor: '#CACACA'
  }
});

export default function ProductCompositionImagesPage() {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [info, setInfo] = useState('');
  const [isLoading, setLoading] = useState(true);
  const { addToast } = useToasts();
  const { id } = useParams();   


  const cookies = parseCookies();

  useEffect(() => {
     getData();
     getProductCompositionInfo();
  }, []);

  const getProductCompositionInfo = useCallback(async () => {    
    try {
      const response = await api.get(`/productComposition/${id}`);
      setInfo(`${response.data.jar.ref} - ${response.data.jar.name} - ${response.data.color.name} - ${response.data.productType.name}`);
    }
    catch(e){
      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });      
      history.push("/admin/products");
    }
  });


 
  const getData = useCallback(async () => {
    try {

      setLoading(true);
      const response = await api.get(`/productComposition/images/${id}`);    
      setData(response.data);
      setLoading(false);

      
    }
    catch(e){
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });        

      setLoading(false);
    } 
  },[]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover esta imagem ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          setLoading(true);
          await api.delete(`/productComposition/image/${id}`);
          setData(data => data.filter(d => d.id != id));
          setLoading(false);
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });    
        setLoading(false);    
      }
  },[]);

  const handleSetDestaque =  useCallback(async (image_id) => {
    try {
        await api.patch(`/productComposition/images/destaque/${image_id}`, {
          destaque: true
        });
        getData();
    }
    catch(e){
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });        
    }
   },[]);

   const handleVistadeCima =  useCallback(async (image_id) => {
    try {
        await api.patch(`/productComposition/images/vistadecima/${image_id}`, {
          vistadecima: true
        });
        getData();
    }
    catch(e){
      addToast(e.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
      });        
    }
   },[]);


  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Imagens da composição do produto - {info}</h4>
            <p className={classes.cardCategoryWhite}>
              Insira as imagens 
            </p>
          </CardHeader>
         
          <CardBody>
 
            <Uploader 
                action={`${process.env.REACT_APP_API_URL}/productComposition/images/${id}`} 
                headers={{authorization: `Bearer ${cookies['criesuavela.token']}`}} 
                name="images"
                accept="image/*"
                fileListVisible={false}
                onSuccess={getData}
                multiple={true}
                draggable>  
              <div style={{lineHeight: 8, backgroundColor: '#CACACA'}}>Clique aqui ou arraste o arquivo de imagem.</div>
            </Uploader>
            <br /><br />

            <GridContainer>
              {!isLoading ? 
              <>
              {data && data.map((data, index) => {  
                return (                                
                  <GridItem key={data.id} xs={12} sm={12} md={3}>            
                  <Card className={data.destaque  ? classes.destaque : classes.root}>                               
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/productComposition/rs/${data.image_name}`}                        
                      />  
                      {data.vistacima && <p style={{textAlign: 'center'}}><strong>Vista de cima</strong></p>}                
                    </CardActionArea>
                    <CardActions style={{flexDirection: 'column'}}>
                      <Button color="danger" round onClick={() => handleDelete(data.id)} simple><Delete /> Remover</Button>    
                      {!data.destaque ? <Button size="sm" color="primary" simple onClick={() => handleSetDestaque(data.id)}>
                        Definir como destaque
                      </Button> : null}         
                       <Button size="sm" color="primary" simple onClick={() => handleVistadeCima(data.id)}>
                        Definir como foto de cima
                      </Button>                                             
                    </CardActions>         
                          

                 </Card>
                 
                 </GridItem>                
                )
               })}
               </> : isLoading ? (
                  <Box mt={6}>
                  <CircularProgress />
                  </Box>               
                ) : (           
                  <p style={{marginTop: 20}}>Nenhuma imagem inserida no momento.</p>
                )} 

          </GridContainer>
         
            <br />
            <Link to="/admin/compositions"><Button color="primary" type="button">Voltar</Button></Link>
           
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
