import 'date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
import React, { useState, useEffect, useCallback } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { setCookie, parseCookies } from 'nookies'
import { history,  useLocation, useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'assets/css/react-confirm-alert.css'; // Import css
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';


import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';


import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button.js';

import api from '../../services/api';

const useStyles = makeStyles(styles);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Orders() {
  const classes = useStyles();
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState('R$ 0,00');
  const { addToast } = useToasts();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const history = useHistory();
  const [ordem, setOrdem] = useState('mais_recentes');
  const [dataInicial, setDataInicial] = useState(new Date(new Date() - (60 * 86400000)));
  const [dataFinal, setDataFinal] = useState(new Date());
  const [id, setId] = useState('');
  let query = useQuery();

  const handleDataInicialChange = (date) => {
    setDataInicial(date);
  };

  const handleDataFinalChange = (date) => {
    setDataFinal(date);
  };

  useEffect(() => {
      getData();
  }, []);

  const handleDownloadXml = useCallback(async (id) => {

    try {
       let cookies = parseCookies();
        window.location.href = `${process.env.REACT_APP_API_URL}/orders/${id}/xml/${cookies['criesuavela.token']}`;
    }
    catch(e){}
  },[]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const orders = [];

   
      const response = await api.post("/orders/search",{ 
        filtros: {
          name,
          email,
          ordem,
          dataInicial,
          dataFinal,
          id,
        }
      });
  
      let totalv = 0;

      response.data.map(o => {
        orders.push({
          id: o.id,
          cliente: o.customer.name,
          status: o.status.name,
          statusid: o.status.id,
          formapg: o.paymentform.name,
          valorpedido: new Intl.NumberFormat('pt-BR', { 
            style: 'currency', 
            currency: 'BRL', 
          }).format(Number(o.total)),
          valordesconto: new Intl.NumberFormat('pt-BR', { 
            style: 'currency', 
            currency: 'BRL', 
          }).format(Number(o.valordesconto)),
          total: new Intl.NumberFormat('pt-BR', { 
            style: 'currency', 
            currency: 'BRL', 
          }).format(Number(o.total) + Number(o.freteValor) - Number(o.valordesconto)),
          frete: new Intl.NumberFormat('pt-BR', { 
            style: 'currency', 
            currency: 'BRL', 
          }).format(o.freteValor),
          created_at:  new Date(o.created_at).toLocaleDateString('pt-br', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
           })
        })       
        
        totalv += Number(o.total) + Number(o.freteValor) - Number(o.valordesconto);
      })
    
  
      setData(orders); 

      setTotal(new Intl.NumberFormat('pt-BR', { 
        style: 'currency', 
        currency: 'BRL', 
      }).format(totalv));

      setLoading(false);
    }
    catch(e){
      console.log(e.message);
      addToast('Ocorreu um erro para atualizar os dados', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      setLoading(false);

    } 
  },[name, email, ordem, dataInicial, dataFinal, id]);

  const handleDelete = useCallback(async (id) => {

    confirmAlert({
      title: 'Confirmação',
      message: 'Tem certeza que deseja remover este registro ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => postDeleteAction(id)
        },
        {
          label: 'Não'         
        }
      ]
    });

  },[name, email, ordem, dataInicial, dataFinal, id]);

  const postDeleteAction =  useCallback(async (id) => {
      try {
          await api.delete(`/orders/${id}`);
          getData();
      }
      catch(e){
        addToast(e.response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });        
      }
  },[]);

  useEffect(() => {

    const code = query.get("code");
    const state = query.get("state");

    if (code && state){
      window.location='/admin/orders/'+state+'?code='+code;
    }
  },[query]);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card> 
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Pedidos</h4>
            <p className={classes.cardCategoryWhite}>
              Pedidos cadastrados no site.
            </p>
          </CardHeader>
          <CardBody>

          <TextField
            variant="outlined"
            margin="normal"       
            fullWidth
            id="name" 
            label="Pesquise pelo número do pedido"
            name="id"
            value={id}
            onChange={(e) => setId(e.target.value)}       
         /> 

          <TextField
            variant="outlined"
            margin="normal"       
            fullWidth
            id="name" 
            label="Pesquise pelo nome do cliente"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}     
         />

        <TextField
            variant="outlined"
            margin="normal"       
            fullWidth
            id="name" 
            label="Pesquise pelo email do cliente"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}        
         /> 

         
          <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBRLocale}>           
             <DatePicker style={{marginTop: 15}} label="Periodo inicial" value={dataInicial} onChange={handleDataInicialChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
           </MuiPickersUtilsProvider>

           <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBRLocale}>           
             <DatePicker style={{marginTop: 25}} label="Periodo final" value={dataFinal} onChange={handleDataFinalChange} format="dd/MM/yyyy" inputVariant="outlined" fullWidth />
           </MuiPickersUtilsProvider>
           
          <FormControl fullWidth variant="outlined" className={classes.formControl} style={{marginTop: 25}}>
            <InputLabel id="ordem_label">Ordenar por:</InputLabel>             
               <Select 
                    labelId="ordem"
                    label="ordenar por:"
                    variant="outlined"                  
                    name="ordem"
                    fullWidth
                    value={ordem}
                    onChange={(e) => setOrdem(e.target.value)}              
                    >       
                    <MenuItem value={"ordem_numero"}>
                        <em>Número do pedido</em>
                      </MenuItem>
                      <MenuItem value={"mais_recentes"}>
                        <em>Mais recentes</em>
                      </MenuItem>
                      <MenuItem value={"mais_antigos"}>
                        <em>Mais antigos</em>
                      </MenuItem>
                                              
              </Select>
           </FormControl>

            <Button color="primary" type="button" style={{marginTop: 20}} onClick={getData}>Filtrar</Button>

            {loading ? (
            <Box mt={6}>
             <CircularProgress />
            </Box>               
            ) : ( 
             <>     
              {data && data.length > 0 ? <Table
                  tableHeaderColor="primary"
                  tableHead={['No pedido', 'Cliente', 'Status', 'Forma Pg', 'Valor Pedido', 'Valor Frete', 'Desconto', 'Total', 'Cadastrado em', '', '']}
                  tableColumn={['id', 'cliente', 'status', 'formapg', 'valorpedido', 'frete', 'valordesconto', 'total', 'created_at', 'edit', 'delete']}
                  tableColumnSize={['5%', '20%', '15%', '15%', '10%', '10%', '10%', '10%', '20%', '1%', '1%']}
                  tableData={data} 
                  resource="orders"              
                  onDelete={(id) => handleDelete(id)} 
                  onDownloadXml={(id) => handleDownloadXml(id)}             
              /> : <p style={{marginTop: 20}}>Nenhum pedido cadastrado no momento.</p>} 

              <h3>Total: {total}</h3>
             </>
            )}
          </CardBody>
        </Card>
      </GridItem>

    </GridContainer>
  );
}
