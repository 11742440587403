import React, {useEffect, useCallback, useState, useRef} from 'react';

import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import FileUploader from 'components/FileUploader/FileUploader';
import Selection from 'components/Selection/Selection.js';


import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditPreset() {
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [type, setType] = useState('Vela');
  const [category, setCategory] = useState('');
  const [scentTopo, setScentTopo] = useState('');
  const [scentMeio, setScentMeio] = useState('');
  const [scentBase, setScentBase] = useState('');
  const [color, setColor] = useState('');
  const [stone, setStone] = useState('');
  const [decoration, setDecoration] = useState('');
  const [jar, setJar] = useState('');
  const [jarImage, setJarImage] = useState('');
  

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [imagem, setImagem] = useState('');
  const [removerImagem, setRemoverImagem] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);


  const handleSave = (async (e) => {

    try { 

      e.preventDefault();

      const name = e.target.name.value;
   
   
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),    
        type: Yup.string().required('Tipo é obrigatório !'),    
        category: Yup.string().required('Categoria é obrigatória !'),              
      });         

      await schema.validate({name, type, category}, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('name', name);
      formData.append('type', type);
      formData.append('category_id', category);
      formData.append('jar_id', jar);
      formData.append('scent_topo_id', scentTopo);
      formData.append('scent_meio_id', scentMeio);
      formData.append('scent_base_id', scentBase);
      formData.append('color_id', color);
      formData.append('stone_id', stone);
      formData.append('decoration_id', decoration);
      formData.append('description', description);
    
      if (selectedFile){
        formData.append('photo', selectedFile);
      }
 
      if (action === 'update'){
        await api.put(`/preset/${id}`, formData);
      }
      else{
        await api.post(`/preset`, formData);
      }

      history.push('/admin/presets');
   
    }
    catch(e){ 


      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
       
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });
             
    }
  }); 
  


  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/preset/${id}`);
      setName(response.data.name);
      setType(response.data.type);
      setCategory(response.data.category_id);
      setImagem(response.data.photo);
      setJar(response.data.jar_id);
      setScentTopo(response.data.scent_topo_id);
      setScentMeio(response.data.scent_meio_id);
      setScentBase(response.data.scent_base_id);
      setColor(response.data.color_id);
      setStone(response.data.stone_id);
      setDecoration(response.data.decoration_id);
      setDescription(response.data.description);
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/presets");
      
    }
  
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Presets</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
            {!loading && <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                <Selection resource="preset_category" label="Categoria Preset" handleChangeValue={(value) => setCategory(value)} marginTop={15} value={category} />
                  
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                  />  

                    <TextField
                      variant="outlined"
                      margin="normal"
                      
                      fullWidth
                      multiline={true}
                      rows={5}
                      id="description"
                      label="Descrição"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      autoFocus
                  />  
            
                  <FormControl fullWidth variant="outlined" className={classes.formControl} style={{ marginTop: 10, marginBottom: 5}}>
                      <InputLabel id="type">Tipo</InputLabel>
                      <Select
                        labelId="type"
                        label="destino"
                        variant="outlined"
                        name="type"
                        fullWidth
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <MenuItem key="vela" value="Vela">Vela</MenuItem>
                        <MenuItem key="cera" value="Cera">Cera</MenuItem>
                     
                      </Select>
                    </FormControl>
           
                    <Selection resource="jar" label="Recipiente" handleChangeValue={(value) => { setJar(value); }} marginTop={15} value={jar} />
                  
                    <Selection resource="scent" filters={{type: 'topo'}} label="Nota de Topo / Saída" handleChangeValue={(value) => setScentTopo(value)} marginTop={15} value={scentTopo} />
                    <Selection resource="scent" filters={{type: 'meio'}}  label="Nota de Corpo / Meio" handleChangeValue={(value) => setScentMeio(value)} marginTop={15} value={scentMeio} />
                    <Selection resource="scent" filters={{type: 'base'}}  label="Nota de Fundo / Base" handleChangeValue={(value) => setScentBase(value)} marginTop={15} value={scentBase} />
                    <Selection resource="color" label="Cor" handleChangeValue={(value) => setColor(value)} marginTop={15} value={color} />

                    {type === 'Vela' && <>
                    <Selection resource="stone" label="Pedra" handleChangeValue={(value) => setStone(value)} marginTop={15} value={stone} />
                    <Selection resource="decoration" label="Decoração" handleChangeValue={(value) => setDecoration(value)} marginTop={15} value={decoration} />
                    </>}

            

                  <br /><br />

                  <FileUploader
                    onFileSelectSuccess={(file) => { 
                       setSelectedFile(file);                     
                       setImagem(null); 
                       setRemoverImagem(false);
                    }}
                    onFileSelectError={({ error }) => addToast(error, {
                      appearance: 'error',
                      autoDismiss: true,
                    })}
                 />

                  {imagem ?
                  <>     
                    <hr />
                       <img src={`${process.env.REACT_APP_IMAGENS_URL_PREFIX}/preset/rs/${imagem}`} height="250" />       
                    <hr />
                  </> : null}


                </GridItem>               
              </GridContainer>}
             
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
