import axios, { AxiosError } from 'axios'

/*
const apiFrete = axios.create({
    baseURL: 'https://sandbox.melhorenvio.com.br',
    headers: {
       Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjQ3NzhkZDE5MmY5MzZiNjllZmE2Y2RhZDUxY2M2MzVmZjFlY2Q1MzM2YzZmNzViZmQwMzdmODg5YzJiYTUwNWYwMjg0OTcwMmMxYjVlYjE3In0.eyJhdWQiOiI5NTYiLCJqdGkiOiI0Nzc4ZGQxOTJmOTM2YjY5ZWZhNmNkYWQ1MWNjNjM1ZmYxZWNkNTMzNmM2Zjc1YmZkMDM3Zjg4OWMyYmE1MDVmMDI4NDk3MDJjMWI1ZWIxNyIsImlhdCI6MTYzMjUxMTM0NSwibmJmIjoxNjMyNTExMzQ1LCJleHAiOjE2NjQwNDczNDUsInN1YiI6Ijg4ZTUwNmYzLWM1MTQtNDFjMy1hODZkLTg2N2RmOTQzOGUwYyIsInNjb3BlcyI6WyJjYXJ0LXJlYWQiLCJjYXJ0LXdyaXRlIiwic2hpcHBpbmctY2FsY3VsYXRlIiwic2hpcHBpbmctY2hlY2tvdXQiLCJzaGlwcGluZy1nZW5lcmF0ZSIsInNoaXBwaW5nLXByZXZpZXciLCJzaGlwcGluZy1wcmludCIsImVjb21tZXJjZS1zaGlwcGluZyJdfQ.L1XJu_cO80etdoZ-oLfwqtUS1A-LnS8SG7z41zMwGFo_7udJ9h5SVPiKZGMr2sm4njLHDCaci6Qfi-62aoq_Dc922MiFpKQoPlbZarw1eN13KY8aQM-yPVIIVhAih1yMBbnO2VvIAELAqfBRsleaXWudOBy8_X1v2Xb8-NX2c07N6BTzcpX3sY-NO5AJ3CpIsjwfF6EqjoBvigdVeTXg4yDWVehlULeqr8_AtvZgn7zyaHmk76OTrxKU2xAxSZwUwVFjXX4mRxzZoXGZKalWrPIocHDFpge9Z0BdqOTqr6HRgTsebAIA_nFvatx6lnen2lnJ3Be98zYQQs2sL-ay8qC0Bg3LP0XO_IyAfmH1lCPRB-bqw2b0OX6xmiTo7_PxpNWTktu_ReVouzPhHvGPC4SsrTYjn-xNyrdxHTbkxmj27hkAwJOyyMPjyZ1yMEPfpeXQrzg-YShl5CNEm-EylqMAMFhaKxGiPeyqmxmYtmddlCQVcazVFPCcmxK8Af88HD29nVgw0oQMy0vl30eaIYBuuCjMxGG5nKoFUwXLgKxnpauyWk5PuHJ_munkcpeQesO4KmUuVkJAD55DEXtmh28B6wf7uWbyp7T1HTjuvP6qlwh3oJyCuVWCH4QDUzLT7F_EhGejoaQf3OsV-TRqoT3_kqdFQluOBRUHwzzcFqU`
    }
});
*/

const apiFrete = axios.create({
    baseURL: 'https://melhorenvio.com.br',
    headers: {
       Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxMTIxOSIsImp0aSI6ImIyOGFiYjAzNzViOGY2Y2YwMjJhY2NmMjVmN2EzMjU0YjUwZGQ4MmM5MWI3ZjI3ZDVjYjk1NDVjZDU1YzhjODQ5MTQxMTZhODcxMzkxNDAzIiwiaWF0IjoxNjkzMjUyMDAwLjc0MTAyNSwibmJmIjoxNjkzMjUyMDAwLjc0MTAyOCwiZXhwIjoxNjk1ODQ0MDAwLjY4NTg3NSwic3ViIjoiMTUwOWJmZGMtZTg5Ny00YTlhLThhZTAtNWFlNzVhZDg4MDhiIiwic2NvcGVzIjpbImNhcnQtcmVhZCIsInNoaXBwaW5nLXByaW50Iiwic2hpcHBpbmctcHJldmlldyIsInNoaXBwaW5nLWdlbmVyYXRlIiwic2hpcHBpbmctY2hlY2tvdXQiLCJzaGlwcGluZy1jYWxjdWxhdGUiLCJjYXJ0LXdyaXRlIl19.vIbnrmxHA2drXDjeIGlsdtT9qD4BwtOhU8cPq438mqrmy2t_vnHFaBQCweXNnb0h-WoxuGeZ7gIAgR2M-Khs_rbeSq-DQZvTeJdwHXKIclbADVDvcpSHSAPFRxszHA13xNQNK97vCespVDN3tSOnr4PZ8Apd9mEHRNMlIHWSSD--KJkrE5CYQhtXvUwrGSIXC_X0rFzttS7LdcqLNSbfTYZsM0-tU7VDRLTH-iaf2qJZ-XuViH08IJWJKX8eqhp0eZWhSm4VUMw8RGzJYOvm4jzXRzwM7qTv9wIO5v4bEexx2M3TTDgZOqGbnx7N26r9-d_EBSrPoBvMdizkkJ65D8CgfS-ygHpIiItD66yaLegNHQWZqmdwijT8lY-GzheJUKIMOgyeC697YI4rGtk20S0NdL2p99G7_wszplP0wFi4XLYQ7R7A_7cRMSSMGKnn0Vo6aqvYLmzY2PVw7D55EUl0ELFezl2wLF4CoxG86HCcvPU13R5SukHldVFUb2thVUlJzFjeXljn8JjzqfPNakxlCbCFkjVW27BDZmsRYpx2GCRWhQJ0g-k0RoP7jtbe-snGz96Krb02CsWMPcJFnqljOJoIixtbHBfIe47QUiuYt6M7YhKi335lLm-wAL6UobijchcPk96jUyjT2GQQi1_LVIckTlvyCNpg7EVIKn0`
    }
});
  

export default apiFrete;