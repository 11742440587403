import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { makeStyles } from '@material-ui/core/styles';

import Navbar from 'components/Navbars/Navbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js';

import bgImage from 'assets/img/menu.jpg';
import logo from 'assets/img/logonova2.png';
import menuRoutes from '../../routes/menu.routes';

import EditBannerPage from '../../pages/Banners/edit';
import EditAdminPage from '../../pages/Admin/edit';
import TextEditPage from '../../pages/Texts/edit';
import EditColorPage from '../../pages/Color/edit';
import EditCouponsPage from '../../pages/Coupons/edit';
import EditCustomersPage from '../../pages/Customers/edit';
import EditOrdersPage from '../../pages/Orders/edit';
import EditRatingsPage from '../../pages/Ratings/edit';
import EditScentPage from '../../pages/Scent/edit';
import EditLabelPage from '../../pages/Label/edit';
import EditDecorationPage from '../../pages/Decoration/edit';
import EditJarPage from '../../pages/Jar/edit';
import EditProductTypePage from '../../pages/ProductType/edit';
import EditProductCompositionPage from '../../pages/ProductComposition/edit';
import ProductCompositionImagesPage from '../../pages/ProductCompositionImages/index';
import ProductImagesPage from '../../pages/ProductImages/index';

import EditCardPage from '../../pages/Card/edit';
import EditStonePage from '../../pages/Stone/edit';
import EditMessagePage from '../../pages/Message/edit';
import EditAcessoryPage from '../../pages/Accessories/edit';
import EditPresetsPage from '../../pages/Presets/edit';
import EditPresetsCategoryPage from '../../pages/PresetsCategory/edit';

let ps;

const switchRoutes = (
  <Switch> 
    <Route path="/admin/banners/:id" isPrivate component={EditBannerPage} /> 
    <Route path="/admin/accessories/:id" isPrivate component={EditAcessoryPage} /> 
    <Route path="/admin/administradores/:id" isPrivate component={EditAdminPage} /> 
    <Route path="/admin/texts/:id" isPrivate component={TextEditPage} />     
    <Route path="/admin/presets/:id" isPrivate component={EditPresetsPage} /> 
    <Route path="/admin/preset_category/:id" isPrivate component={EditPresetsCategoryPage} /> 
    <Route path="/admin/colors/:id" isPrivate component={EditColorPage} /> 
    <Route path="/admin/scents/:id" isPrivate component={EditScentPage} /> 
    <Route path="/admin/labels/:id" isPrivate component={EditLabelPage} />     
    <Route path="/admin/decorations/:id" isPrivate component={EditDecorationPage} /> 
    <Route path="/admin/jars/:id" isPrivate component={EditJarPage} /> 
    <Route path="/admin/coupons/:id" isPrivate component={EditCouponsPage} /> 
    <Route path="/admin/customers/:id" isPrivate component={EditCustomersPage} /> 
    <Route path="/admin/orders/:id" isPrivate component={EditOrdersPage} /> 
    <Route path="/admin/ratings/:id" isPrivate component={EditRatingsPage} /> 
    <Route path="/admin/producttype/:id" isPrivate component={EditProductTypePage} /> 
    <Route path="/admin/compositions/images/:id" isPrivate component={ProductCompositionImagesPage} /> 
    <Route path="/admin/products/images/:id" isPrivate component={ProductImagesPage} /> 
    <Route path="/admin/compositions/:id" isPrivate component={EditProductCompositionPage} /> 

    <Route path="/admin/stones/:id" isPrivate component={EditStonePage} /> 
    <Route path="/admin/cards/:id" isPrivate component={EditCardPage} /> 
    <Route path="/admin/messages/:id" isPrivate component={EditMessagePage} /> 

    {menuRoutes.map((prop, key) => {     
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          /> 
        );    
    })}  
    
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();

  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState('blue');
  const [fixedClasses, setFixedClasses] = React.useState('dropdown show');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === 'dropdown') {
      setFixedClasses('dropdown show');
    } else {
      setFixedClasses('dropdown');
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => window.location.pathname !== '/admin/maps';
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={menuRoutes}
        logoText="Crie sua vela"
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={menuRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}

      </div>
    </div>
  );
}
