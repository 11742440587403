import React, {useEffect, useCallback, useState, useRef} from 'react';

import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const editorConfiguration = {
  toolbar: [ 'bold', 'italic', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',  'blockquote', 'link' ] 
}; 

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';


import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditMessage() {

  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();
      setLoading(true);
     
      const name = e.target.name.value; 

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'), 
        text: Yup.string().required('Texto é obrigatório !'),              
      });         

      await schema.validate({name, text}, {
        abortEarly: false,
      });

      const formData = {
        name,
        text
      }  
 
      if (action === 'update'){
        await api.put(`/message/${id}`, formData);
      }
      else{
        await api.post(`/message`, formData);
      }
      
      history.push('/admin/messages');
   
    }
    catch(e){ 

      setLoading(false);

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }     
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });           
  
    }
  },[action, text]); 
  
  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/message/${id}`);
      setName(response.data.name);   
      setText(response.data.text);    
      
    }
    catch(e){

      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/messages");
      
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados da Mensagem</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
             {!loading ? <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoFocus
                  />

                 <CKEditor
                    editor={ Editor }
                    config={ editorConfiguration }
                    data={text}
                    onReady={(editor) => {       
                      editor.editing.view.change((writer) => {
                      writer.setStyle(
                          "height",
                          "400px",
                          editor.editing.view.document.getRoot()
                      );
                      });
                    }}    
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();           
                      setText(data);
                  } }           
                 />

                </GridItem>               
              </GridContainer> : <Box mt={6}><CircularProgress /></Box> }
             
            </CardBody>
            {!loading ? <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter> : null}
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
