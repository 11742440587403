// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import Assignment from '@material-ui/icons/Assignment';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Stars from '@material-ui/icons/Stars';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
import Build from '@material-ui/icons/Build';
import Loyalty from '@material-ui/icons/Loyalty';

// core components/views for Admin layout
import AdminPage from '../pages/Admin';
import DashboardPage from '../pages/Dashboard';
import TextsPage from '../pages/Texts';
import CustomersPage from '../pages/Customers';
import OrdersPage from '../pages/Orders';
import RatingsPage from '../pages/Ratings';
import CouponsPage from '../pages/Coupons';
import BannersPage from '../pages/Banners';
import StonePage from '../pages/Stone';
import CardPage from '../pages/Card';
import MessagePage from '../pages/Message';


import ConfigurationsPage from '../pages/Configurations';

import ColorPage from '../pages/Color';
import ScentPage from '../pages/Scent';
import LabelPage from '../pages/Label';
import DecorationPage from '../pages/Decoration';
import JarPage from '../pages/Jar';
import ProductCompositionPage from '../pages/ProductComposition';
import ProductTypePage from '../pages/ProductType';
import AcessoryPage from '../pages/Accessories';
import PresetPage from '../pages/Presets';
import PresetCategoryPage from '../pages/PresetsCategory';

const menuRoutes = [
  {
    path: '/painel',
    name: 'Painel',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin'
  },
  {
    path: '/administradores',
    name: 'Administradores',
    icon: Person,
    component: AdminPage,
    layout: '/admin',
  },
  {
    path: '/customers',
    name: 'Clientes',
    icon: Person,
    component: CustomersPage,
    layout: '/admin',
  },
  {
    path: '/coupons',
    name: 'Cupons',
    icon: Loyalty,
    component: CouponsPage,
    layout: '/admin',
  },
  {
    path: '/orders',
    name: 'Pedidos',
    icon: ShoppingCart,
    component: OrdersPage,
    layout: '/admin',
  },
  { 
    path: '/ratings',
    name: 'Avaliações',
    icon: Stars,
    component: RatingsPage,
    layout: '/admin',
  }, 
  {
    path: '/presetscategory',
    name: 'Categoria de Presets',
    icon: Assignment,
    component: PresetCategoryPage,
    layout: '/admin',
  },  
  {
    path: '/presets',
    name: 'Presets',
    icon: Assignment,
    component: PresetPage,
    layout: '/admin',
  },  
  {
    path: '/acessories',
    name: 'Acessórios',
    icon: Assignment,
    component: AcessoryPage,
    layout: '/admin',
  },  
  {
    path: '/colors',
    name: 'Cores',
    icon: Assignment,
    component: ColorPage,
    layout: '/admin',
  },  
  {
    path: '/scents',
    name: 'Aromas',
    icon: Assignment,
    component: ScentPage,
    layout: '/admin',
  },  
  {
    path: '/stones',
    name: 'Pedras',
    icon: Assignment,
    component: StonePage,
    layout: '/admin',
  },  
  {
    path: '/card',
    name: 'Cartões',
    icon: Assignment,
    component: CardPage,
    layout: '/admin',
  },  
  {
    path: '/labels',
    name: 'Rótulos',
    icon: Assignment,
    component: LabelPage,
    layout: '/admin',
  },   
  {
    path: '/decorations',
    name: 'Decorações',
    icon: Assignment,
    component: DecorationPage,
    layout: '/admin',
  }, 
  {
    path: '/jar',
    name: 'Jarras',
    icon: Assignment,
    component: JarPage,
    layout: '/admin',
  }, 
  {
    path: '/producttype',
    name: 'Tipo de Produto',
    icon: Assignment,
    component: ProductTypePage,
    layout: '/admin',
  }, 
  {
    path: '/compositions',
    name: 'Composições de Produto',
    icon: Assignment,
    component: ProductCompositionPage,
    layout: '/admin',
  },
  {
    path: '/messages',
    name: 'Mensagens',
    icon: Assignment,
    component: MessagePage,
    layout: '/admin',
  },
  {
    path: '/banners',
    name: 'Banners Site',
    icon: ViewCarousel,
    component: BannersPage,
    layout: '/admin',
  },
  {
    path: '/texts',
    name: 'Textos Site',
    icon: Assignment,
    component: TextsPage,
    layout: '/admin',
  },
  {
    path: '/configurations',
    name: 'Configuracoes Gerais',
    icon: Build,
    component: ConfigurationsPage,
    layout: '/admin',
  }, 

];

export default menuRoutes;
