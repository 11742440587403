import React, {useEffect, useCallback, useState, useRef} from 'react';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { useParams, useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

const useStyles = makeStyles(styles);

export default function EditAdmin() {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState('create');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const formRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts()

  const { id } = useParams();   
  const classes = useStyles();

  useEffect(() => {
    
    if (id != "new"){
      setAction("update");
    }
  },[]);

  useEffect(() => {
    if (action === "update"){
      loadData(id);
    }
  },[action]);

  const handleSave = useCallback(async (e) => {

    try { 

      e.preventDefault();

      const email = e.target.email.value;
      const password = e.target.password.value; 
      const name = e.target.name.value;

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório !'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Digite um email valido'),
        password: action === 'create' ? Yup.string().required('Senha obrigatória')
        .min(4, 'Mínimo de 4 caracteres no campo senha') : password ? Yup.string().min(4, 'Mínimo de 4 caracteres no campo senha') : null        
      });         

      await schema.validate({name, email, password}, {
        abortEarly: false,
      });

      const postData = {  
        name,
        email,
        password
      };

      if (action === 'update'){
        postData.id = id;
      }


      await api.post("/admin", postData);

      history.push('/admin/administradores');
   
    }
    catch(e){ 

      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e); 

        for (var [, value] of Object.entries(errors)) {
          addToast(value, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
        return;
      }    
      
      
      addToast(e.response ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });

     
  
    }
  },[action]);

  const loadData = useCallback(async () => {    
    try {
      const response = await api.get(`/admin/${id}`);
      setName(response.data.name);
      setEmail(response.data.email);

    }
    catch(e){
   
      addToast(e.response.data.message ? e.response.data.message : 'Ocorreu um erro !', {
        appearance: 'error',
        autoDismiss: true,
      });       
      
      history.push("/admin/administradores");
    }
  });


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <form className={classes.form} ref={formRef} onSubmit={handleSave}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Dados do Administrador</h4>
              <p className={classes.cardCategoryWhite}>Complete os dados.</p>
            </CardHeader>
            <CardBody>
           
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nome"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}                     
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                     
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                <TextField
                      variant="outlined"
                      margin="normal"
                      required={action === 'create' ? true : false}
                      fullWidth
                      id="password"
                      label="Senha"
                      name="password"
                      type="password"
                      
                     
                  />
                </GridItem>
              </GridContainer>
             
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit">Salvar</Button>
            </CardFooter>
          </form>
          </Card>
        </GridItem>
        
      </GridContainer>
    </div>
  );
}
